<template>
    <div class="content-main">
        <div class="content-header">
            <h1>产品信息</h1>
        </div>
        <div>
            <el-divider></el-divider>
        </div>
        <div class="content-nav">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }"><i class="el-icon-s-home"></i></el-breadcrumb-item>
                <el-breadcrumb-item>产品信息</el-breadcrumb-item>
                <el-breadcrumb-item v-if="info.type == 1">补充包</el-breadcrumb-item>
                <el-breadcrumb-item v-if="info.type == 2">预组</el-breadcrumb-item>
                <el-breadcrumb-item v-if="info.type == 3">其他</el-breadcrumb-item>
                <el-breadcrumb-item>{{ info.name }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <!--详情区-->
        <div class="product-detail">
            <!--title-->
            <div class="product-detail-title">
                <div>
                    <div class="product-type" v-if="info.type == 1" style="background-color:#e50525 ">补充包</div>
                    <div class="product-type" v-if="info.type == 2" style="background-color:#1c8531 ">预组</div>
                    <div class="product-type" v-if="info.type == 3" style="background-color:#4775b3 ">其他</div>
                </div>
                <div>
                    <span>[{{ info.no }}]</span><br>
                    <span>{{ info.name }}</span>
                </div>
            </div>
            <!--内容-->
            <div class="product-detail-content">
                <!--图片-->
                <div class="product-detail-content-img">
                    <div v-if="info.id > 0">
                        <el-image :src="info.img + '?x-oss-process=image/resize,h_600,w_600'" :lazy="true"
                            @error="imgLoadErr" v-if="info.img">
                        </el-image>
                    </div>

                </div>
                <!--详情参数-->
                <div class="product-detail-content-detail">
                    <el-divider></el-divider>
                    <el-row>
                        <el-col :span="6">发布日期</el-col>
                        <el-col :span="18" style="font-weight: bold">{{ info.sale }}</el-col>
                    </el-row>
                    <el-divider></el-divider>
                    <el-row>
                        <el-col :span="6">建议零售价</el-col>
                        <el-col :span="18" style="font-weight: bold" v-html="info.steer_sale"></el-col>
                    </el-row>
                    <el-divider></el-divider>
                    <el-row>
                        <div v-if="info.select_n_g == 'nation'">
                            <el-col :span="6">国家</el-col>
                            <el-col :span="18" style="font-weight: bold">{{ info.nation }}</el-col>
                        </div>
                        <div v-if="info.select_n_g == 'group'">
                            <el-col :span="6">集团</el-col>
                            <el-col :span="18" style="font-weight: bold">{{ info.group }}</el-col>
                        </div>
                    </el-row>
                    <el-divider></el-divider>
                    <el-row>
                        <el-col :span="6">卡种数量</el-col>
                        <el-col :span="18" style="font-weight: bold;white-space:pre-line;" v-html="info.style">
                        </el-col>
                    </el-row>
                    <el-divider></el-divider>
                    <el-row v-if="info.activity_show">
                        <el-col :span="6">活动</el-col>
                        <el-col :span="18" style="font-weight: bold;">{{ info.activity }}</el-col>
                    </el-row>
                    <el-divider></el-divider>

                </div>
            </div>
            <div class="m-clear-both"></div>

        </div>
        <!--视频区-->
        <div v-if="info.video_show">
            <div style="margin: 0 auto;width: 300px">
                <h3 class="m-title">产品视频</h3>
            </div>
            <div class="product-video" v-html="info.video"></div>
        </div>


        <!--富文本区-->
        <div class="full-text-area" v-html="info.content">


        </div>
        <!--链接商品区-->
        <div>
            <div style="margin: 0 auto;width: 300px">
                <h3 class="m-title">相关产品</h3>
            </div>
            <div class="product-link-list">
                <ProductItem v-for="item in info.otherProducts" :key="item"
                    @click.native="$router.push({ path: '/index/productInfo', query: { id: item.id } })" :info="item">
                </ProductItem>
            </div>
            <!--总览区-->
            <div class="back-product-top m-a-none">
                <router-link to="/index/productList">
                    <i class="el-icon-caret-right"></i>返回产品信息TOP
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import ProductItem from './components/product-item'
export default {
    name: "info",
    components: { ProductItem },
    data() {
        return {
            info: {}
        }
    },
    methods: {
        getInfo() {
            let id = this.$route.query.id;
            this.$axios.post("site/siteProduct/getInfo", { id })
                .then((res) => {
                    if (res.data.code == 1000) {
                        this.info = res.data.data;
                    } else {
                        this.$message.error(res.data.msg)
                    }
                })
        },
        imgLoadErr(e) {
            console.log(e)
        }
    },
    created() {
        this.getInfo();
    }

}
</script>

<style scoped>
pre {
    /*white-space: pre-wrap;
        word-wrap: break-word;*/
    /*word-wrap: break-word;*/

    font-weight: bold;
    font-family: 'Alibaba-PuHuiTi-Regular', 'FOT-SkipStd-L', Helvetica, Arial, sans-serif;
    line-height: 25px;
}

.content-main {
    text-align: center;
    background-color: #ffffff;
}

.content-nav {
    width: 60%;
    margin: 0 auto;
}

.product-detail-content {
    padding-top: 30px;
}

.product-detail-content>div {
    float: left;
    width: 45%;
}

.product-detail-content-detail {
    padding-left: 20px;
}

.product-detail-content-img {
    padding-top: 50px;
}

.product-link-list {
    background-color: #f0f0f0;
    width: 98%;
    height: auto;
    padding: 20px 1%;
    text-align: center;
}

.product-link-list>div {
    width: 330px;
    display: inline-block;
    margin: 0 10px;
}

.back-product-top {
    height: 100px;
}

.back-product-top>a {
    display: block;
    height: 66px;
    border: 1px solid black;
    width: 274px;
    margin: 40px auto 20px auto;
    line-height: 66px;
    font-size: 26px;
    font-weight: bold;
}

.product-video {
    background-image: url("../../../assets/img/backimg-black.jpg");
    height: 308px;
}


.full-text-area {
    width: 68%;
    margin: 0 auto;
}

.product-detail {
    width: 60%;
    margin: 0 auto;
    text-align: left;
    padding-top: 30px;
}

.product-detail-title {
    height: 66px;
}

.product-detail-title>div {
    float: left;
}

.product-detail-title>div>div {
    text-align: center;
    height: 25px;
    line-height: 25px;
    margin-top: 23px;
    margin-right: 22px;
}

.product-detail-title span {
    font-weight: bold;
    font-size: 22px;
    line-height: 33px;
}

.product-type {
    background-color: #e40020;
    color: white;
    width: 150px;
}
</style>
<style>
.product-video>iframe {
    height: 260px !important;
    width: 400px !important;
    margin-top: 24px !important;
}
</style>
